.alertSuccess {
  color: #001e38 !important;
}

.ant-notification-notice-message {
  margin-bottom: 10px !important;
  margin-left: 34px !important;
}
.ant-notification-notice {
  padding: 16px !important;
}
.ant-notification-notice-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  margin-left: 0px !important;
}
body > div:first-child {
  height: 100%;
}
.ant-layout {
  height: 100%;
}

body {
  overflow: hidden;
}

.enddate-picker .ant-picker-now {
  display: none;
}

  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px;
    border-radius: 14px;
  }

 ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9a9a9a;
  }
